/* eslint-disable */

import { CheckOutlined, CopyOutlined, FolderOpenOutlined, ShoppingCartOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Typography, Image, Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EntityType } from '../../../../../../types.generated';
import { capitalizeFirstLetterOnly } from '../../../../../shared/textUtil';
import { useEntityRegistry } from '../../../../../useEntityRegistry';
import { IconStyleType } from '../../../../Entity';
import { ANTD_GRAY } from '../../../constants';
import { useEntityData } from '../../../EntityContext';
import { useEntityPath } from '../utils';
import analytics, { EventType, EntityActionType } from '../../../../../analytics';

const LogoContainer = styled.span`
    margin-right: 10px;
`;

const PreviewImage = styled(Image)`
    max-height: 17px;
    width: auto;
    object-fit: contain;
    background-color: transparent;
`;

const EntityTitle = styled(Typography.Title)`
    &&& {
        margin-bottom: 0;
        word-break: break-all;
    }
`;

const PlatformContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

const PlatformText = styled(Typography.Text)`
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: ${ANTD_GRAY[7]};
`;

const EntityCountText = styled(Typography.Text)`
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: ${ANTD_GRAY[7]};
`;

const PlatformDivider = styled.div`
    display: inline-block;
    padding-left: 10px;
    margin-right: 10px;
    border-right: 1px solid ${ANTD_GRAY[4]};
    height: 18px;
    vertical-align: text-top;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    margin-bottom: 4px;
`;

const MainHeaderContent = styled.div`
    flex: 1;
`;

const ExternalLinkButton = styled(Button)`
    margin-right: 12px;
`;

const TypeIcon = styled.span`
    margin-right: 8px;
`;

const ContainerText = styled(Typography.Text)`
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: ${ANTD_GRAY[9]};
`;

const ContainerIcon = styled(FolderOpenOutlined)`
    &&& {
        font-size: 12px;
        margin-right: 4px;
    }
`;

export const EntityHeader = () => {
    const { urn, entityType, entityData } = useEntityData();
    const entityRegistry = useEntityRegistry();
    const [copiedUrn, setCopiedUrn] = useState(false);
    const basePlatformName = entityData?.platform?.properties?.displayName || entityData?.platform?.name;
    const platformName = capitalizeFirstLetterOnly(basePlatformName);
    const platformLogoUrl = entityData?.platform?.properties?.logoUrl;
    const entityLogoComponent = entityRegistry.getIcon(entityType, 12, IconStyleType.ACCENT);
    const entityTypeCased =
        (entityData?.subTypes?.typeNames?.length && capitalizeFirstLetterOnly(entityData?.subTypes.typeNames[0])) ||
        entityRegistry.getEntityName(entityType);
    const entityPath = useEntityPath(entityType, urn);
    const externalUrl = entityData?.externalUrl || undefined;
    const hasExternalUrl = !!externalUrl;

    const sendAnalytics = () => {
        analytics.event({
            type: EventType.EntityActionEvent,
            actionType: EntityActionType.ClickExternalUrl,
            entityType,
            entityUrn: urn,
        });
    };

    const redirectToJira = (dataType, urn) => {

        // const owners = entityData?.ownership?.owners;
        const urlJIRA = 'https://ntuclink.atlassian.net/servicedesk/customer/portal/22/group/63/create/11564?';

        const dataChampionDetail = {
            'fponprd': {
                'ALL': 'winson_lim@ntucenterprise.sg'
            },
            'cds-sc-data-cloud-prod': {
                'ALL': 'munivinay.kamisetty@gls.com.sg'
            },
            'fairprice-bigquery': {
                'ALL': 'xiao_dong.qian@fairprice.com.sg'
            },
            'ff-wangcafe-prod': {
                'ALL': 'qi_da.yeo@fairprice.com.sg'
            },
            'ne-central-data-cloud-prod': {
                'ALL': 'jinwei.wang@ntucenterprise.sg', 
                'data_asset': 'quan.tovan@ntucenterprise.sg',
            },
            'ne-corp-it-production': {
                'automation_ssc': 'vanessa.ng1@fairprice.com.sg', 
            },
            'ne-data-monetisation-prod': {
                'ALL': 'yiqun_hu@ntucenterprise.sg'
            },
            'ne-finance-data-cloud-prod': {
                'ALL': 'adelle.goh@fairpricegroup.sg'
            },
            'ne-fp-hr-data-cloud-production': {
                'ALL': 'chaitanya@fairpricegroup.sg'
            },
            'ne-fprt-data-cloud-production': {
                'ALL': 'xiao_dong.qian@fairprice.com.sg'
            },
            'ne-int-audit-data-cloud-prod': {
                'ALL': 'gavin.ng@fairprice.com.sg'
            },
            'ne-link-cust-svc-ods-prod': {
                'ALL': 'wayne.tan@fairpricegroup.sg'
            },
            'ne-link-data-cloud-production': {
                'ALL': 'wayne.tan@fairpricegroup.sg'
            },
            'ne-link-laksa-production': {
                'ALL': 'natalya@ntuclink.com.sg'
            },
            'ne-obfs-data-cloud-prod': {
                'ALL': 'suraj.taneja@ntucenterprise.sg'
            },
            'ntuc-coe-ds-prod': {
                'ALL': 'yiqun_hu@ntucenterprise.sg'
            },
            'ntuc-coe-ds-uat': {
                'ALL': 'yiqun_hu@ntucenterprise.sg'
            },
            'sc-moh-data-cloud-production': {
                'ALL': 'munivinay.kamisetty@gls.com.sg'
            },
            'fp-marketing-tech-dev': {
                'ALL': 'wayne.tan@fairpricegroup.sg'
            },
            'fp-marketing-tech-production': {
                'ALL': 'wayne.tan@fairpricegroup.sg'
            },
            'ne-trustbank-sharing-prod': {
                'ALL': 'xiao_dong.qian@fairprice.com.sg'
            },
            'nh-data-cloud-prod': {
                'ALL': 'leandro_gerhardinger@fairpricegroup.sg'
            }

        }

        if (dataType === 'DATASET' && urn) {
            const urnFullTable = urn.split(',')[1];
            const splitedFullTable = urnFullTable.split('.');
            const bqProject = splitedFullTable[0];
            const bqDataset = splitedFullTable[1];

            const bqFullDataset = splitedFullTable[0] + "." + splitedFullTable[1];

            const dataChampion = dataChampionDetail[bqProject] && (dataChampionDetail[bqProject][bqDataset] || dataChampionDetail[bqProject]['ALL']);

            // const descriptionField = '&description=Permission: roles/bigquery.dataViewer'+  '%0ADataset: ' + bqFullDataset + '%0AData Champion: ' + dataChampion;
            const descriptionField = '&description=Apply Permissions from DataPortal'
            // customfield_10760: References
            // customfield_10965: Site/Entity
            // customfield_10361: BU
            // customfield_10495: Billing BU of User
            // customfield_10362: Department of User
            // customfield_10691: Bigquery Role
            // customfield_10365: Name of datasets required (multiple choices)
            // customfield_10360: Approvers | Data Champions
            // summary: Summary
            // description: Description
            const JIRAParams = {
                // 'customfield_10760': '',
                'customfield_10965': '',
                'customfield_10361': '',
                'customfield_10495': '',
                'customfield_10362': '',
                'customfield_10691': '12904', //value: roles/bigquery.dataViewer - For Project or Dataset or Table/View Level Access
                // 'customfield_10365': '12210', //Other Datasets (share details in Description) or when Not Applicable.
                'customfield_10360': '',
                'summary': 'Apply Permissions for DATASET ' + bqFullDataset,
                'customfield_11148': bqProject,
                'customfield_11149': bqDataset,
                'customfield_11147': dataChampion

            };
            
            const urlJIRAParams = new URLSearchParams(JIRAParams).toString();
            var fullJIRAURL = urlJIRA + urlJIRAParams + descriptionField
            // if (dataChampion){
            //     const references = '&customfield_10760=Data Champion of DATASET ' + bqFullDataset + ' is ' + dataChampion;
            //     fullJIRAURL =  fullJIRAURL + references;
            // }
            window.open(fullJIRAURL, '_blank');

        }
    };

    const redirectToBigQuery = (dataType, urn) => {
        // const owners = entityData?.ownership?.owners;
        const urlBigQuery = 'https://console.cloud.google.com/bigquery?';
        if (dataType === 'DATASET' && urn) {
            const urnFullTable = urn.split(',')[1];
            const splitedFullTable = urnFullTable.split('.');
            const bqProject = splitedFullTable[0];
            const bqDataset = splitedFullTable[1];
            const bqTable = splitedFullTable[2];

            const bigQueryParams = {
                'project': bqProject,
                'd': bqDataset,
                'p': bqProject,
                't': bqTable,
                'page': 'table'
            }
            const urlBigQueryParams = new URLSearchParams(bigQueryParams).toString();
            var fullBigQueryURL = urlBigQuery + urlBigQueryParams;
            window.open(fullBigQueryURL, '_blank');
        }
    };

    const entityCount = entityData?.entityCount;
    const typeIcon = entityRegistry.getIcon(entityType, 12, IconStyleType.ACCENT);
    const container = entityData?.container;
    return (
        <HeaderContainer>
            <MainHeaderContent>
                <PlatformContent>
                    {platformName && (
                        <LogoContainer>
                            {(!!platformLogoUrl && (
                                <PreviewImage preview={false} src={platformLogoUrl} alt={platformName} />
                            )) ||
                                entityLogoComponent}
                        </LogoContainer>
                    )}
                    <PlatformText>{platformName}</PlatformText>
                    {(platformLogoUrl || platformName) && <PlatformDivider />}
                    {typeIcon && <TypeIcon>{typeIcon}</TypeIcon>}
                    <PlatformText>{entityData?.entityTypeOverride || entityTypeCased}</PlatformText>
                    {container && (
                        <Link to={entityRegistry.getEntityUrl(EntityType.Container, container?.urn)}>
                            <PlatformDivider />
                            <ContainerIcon
                                style={{
                                    color: ANTD_GRAY[9],
                                }}
                            />
                            <ContainerText>
                                {entityRegistry.getDisplayName(EntityType.Container, container)}
                            </ContainerText>
                        </Link>
                    )}
                    {entityCount && entityCount > 0 ? (
                        <>
                            <PlatformDivider />
                            <EntityCountText>{entityCount.toLocaleString()} entities</EntityCountText>
                        </>
                    ) : null}
                </PlatformContent>
                <Link to={entityPath}>
                    <EntityTitle level={3}>{entityData?.name || ' '}</EntityTitle>
                </Link>
            </MainHeaderContent>
            {hasExternalUrl && (
                <ExternalLinkButton href={externalUrl} onClick={sendAnalytics}>
                    View in {platformName}
                </ExternalLinkButton>
            )}
            {entityType === 'DATASET' && (
                <>
                    <Tooltip title="Apply Permission">
                        <ExternalLinkButton
                            icon={<ShoppingCartOutlined />}
                            onClick={() => redirectToJira(entityType, urn)}
                        >
                            Apply Permission
                        </ExternalLinkButton>
                    </Tooltip>

                    <Tooltip title="Open in BigQuery">
                        <ExternalLinkButton
                            icon={<DatabaseOutlined />}
                            onClick={() => redirectToBigQuery(entityType, urn)}
                        >
                            Open in BigQuery
                        </ExternalLinkButton>
                    </Tooltip>
                </>
            )}
            
            <Tooltip title="Copy URN. An URN uniquely identifies an entity on DataHub.">
                <Button
                    icon={copiedUrn ? <CheckOutlined /> : <CopyOutlined />}
                    onClick={() => {
                        navigator.clipboard.writeText(urn);
                        setCopiedUrn(true);
                    }}
                />
            </Tooltip>
        </HeaderContainer>
    );
};
